import { getStyleOverrides } from '@/utils/getStyleOverrides';
import { ThemeOptions } from '@mui/material/styles';

export const Alert: ThemeOptions = {
	components: {
		MuiAlert: {
			styleOverrides: {
				standardError: {
					color: '#000',
					border: '#CA251F solid 1px !important',
					' .MuiAlert-icon': { display: 'flex', alignItems: 'center' },
				},
				root: {
          fontSize: '14px',
          fontWeight: 400,
          fontFamily: 'Inter',
          padding: '16px',
          color: '#323638',
          borderRadius: '4px',
          '& .MuiAlert-message, .MuiAlert-icon, .MuiAlert-action, .MuiAlert-action button': {
            padding: 0,
            opacity: 1,
          },
          '& .MuiAlert-icon': {
            marginRight: '8px',
            alignItems: 'center',
          },
          '& .MuiAlert-message': {
            marginRight: '16px',
          },
          '& .MuiAlert-action': {
            margin: 0,
          },
          '& .MuiAlert-action .MuiSvgIcon-root': {
            width: '16px',
            height: '16px',
          },
          '& .MuiSvgIcon-root': {
            width: '16px',
            height: '16px',
          },
          '& .MuiIconButton-root:hover': {
            'backgroundColor': '#cccccc0a'
          },
          '&.MuiAlert-standardSuccess': {
            border: `1px solid #008060`,
            background: '#ECF5F2',
          },
          '&.MuiAlert-standardError': {
            border: `1px solid #CA251F`,
            background: '#FEECEC',
          },
          '&.MuiAlert-standardWarning': {
            border: `1px solid #A46000`,
            background: '#FDF6ED',
          },
          '&.MuiAlert-standardInfo': {
            border: `1px solid #006A87`,
            background: '#E9F3F6',
          },
        },
			},
		},
	},
};
