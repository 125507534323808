/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ItemWrap } from '@/components/layouts/ItemWrap';
import { LayoutSlot } from '@/components/layouts/LayoutSlot';
import { layoutMainSX } from '@/components/layouts/styles/main';
import { Layout } from '@/data/types/Layout';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';

export const DoubleStack: FC<{ layout: Layout }> = ({ layout }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const secondSlotName = layout?.slots?.second?.at(0)?.name;
	return layout ? (
		<>
			<LayoutSlot slot={layout.slots.header} />
			<Stack
				component="main"
				alignItems="center"
				sx={layoutMainSX(layout.slots.first?.length || 0, secondSlotName, isMobile)}
			>
				<LayoutSlot slot={layout.slots.first} ItemWrap={ItemWrap} />
				<LayoutSlot slot={layout.slots.second} ItemWrap={ItemWrap} />
			</Stack>
			<LayoutSlot slot={layout.slots.footer} />
		</>
	) : null;
};
