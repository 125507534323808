/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { FC } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useNotifications } from '@/data/Content/Notifications';
import { snackBarSX } from '@/components/content/Notifications/styles/snackbar';
import { alertSX } from '@/components/content/Notifications/styles/alert';
import React from 'react';
import CustomErrorIcon from '@/components/content/Notifications/styles/icons/CustomErrorIcon';
import CustomWarningIcon from '@/components/content/Notifications/styles/icons/CustomWarningIcon';
import CustomSuccessIcon from '@/components/content/Notifications/styles/icons/CustomSuccessIcon';
import CustomInfoIcon from '@/components/content/Notifications/styles/icons/CustomInfoIcon';

export const Notifications: FC = () => {
	const { message, clearMessage, sessionError } = useNotifications();

	return (
			<Snackbar
				open={message.text ? true : false}
				anchorOrigin={message.anchorOrigin ?? { vertical: 'bottom', horizontal: 'center' }}
				ClickAwayListenerProps={{
					onClickAway: sessionError?.type !== 'session-error' ? clearMessage : () => null,
					mouseEvent: 'onMouseDown',
				}}
				sx={snackBarSX}
				autoHideDuration={null}
			>
				<Alert
					severity={message.severity ?? 'info'}
					onClose={clearMessage}
					sx={alertSX}
					icon={
						message.severity === 'error' ? React.createElement(CustomErrorIcon) :
							message.severity === 'warning' ? React.createElement(CustomWarningIcon) :
								message.severity === 'success' ? React.createElement(CustomSuccessIcon) :
									message.severity === 'info' ? React.createElement(CustomInfoIcon) :
										null // Default if none of the conditions match
					}
				>
					{message.text}
				</Alert>
			</Snackbar>
	);
};
