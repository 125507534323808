/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material';

export const layoutMainSX = (firstSlotCount: number, secondSlotName?: string | undefined, isMobile?: boolean): SxProps<Theme> => ({
	pt: (theme) => (firstSlotCount === 0 ? (!isMobile && secondSlotName && secondSlotName === 'QuickOrder') ? '74px' : theme.dimensions.contentSpacing : 0),
	fontSize: 'body2.fontSize',
	gap: 0,
});
